<template>
	<div class="sidebar-items">
		<router-link
			to="/"
			class="logo"
			:aria-label="$t('sidebar-menu-item.home')"
			v-tooltip="{
				disabled: props.isExpanded,
				content: $t('sidebar-menu-item.home')
			}"
		>
			<img :src="getLogoImg" :alt="`logo - ${$clientName}`" />
		</router-link>

		<div class="items">
			<div>
				<nav>
					<sidebar-item
						icon="location"
						:to="{ name: 'boxes-map' }"
						:is-expanded="props.isExpanded"
						data-testid="boxes-map-link"
					>
						{{ $t("sidebar-menu-item.map") }}
					</sidebar-item>
					<sidebar-item
						data-testid="boxes-link"
						icon="tower"
						:to="{ name: 'boxes' }"
						:is-expanded="props.isExpanded"
						:is-active="
							isRouteActiveByRouteName(['boxes', 'box-v1', 'box'])
						"
					>
						{{ $t("client-name-boxes") }}
					</sidebar-item>
					<sidebar-item
						data-testid="hubs-link"
						icon="bridge"
						:to="{ name: 'hubs' }"
						:is-expanded="props.isExpanded"
						:is-active="
							isRouteActiveByRouteName(['hubs', 'hub-v1'])
						"
					>
						{{ $t("client-name-hubs") }}
					</sidebar-item>
					<sidebar-item
						data-testid="alarms-link"
						icon="alarm"
						:to="{ name: 'alarms' }"
						:is-expanded="props.isExpanded"
					>
						{{ $t("alarms") }}
					</sidebar-item>
					<hr v-if="!isReadOnlyUser" />
					<sidebar-item
						v-if="!isReadOnlyUser"
						icon="files"
						:to="{ name: 'file-resources-management' }"
						:is-expanded="props.isExpanded"
						data-testid="files-link"
					>
						{{ $t("views.file-resources-management.title") }}
					</sidebar-item>

					<sidebar-item
						v-if="!isReadOnlyUser"
						icon="organization"
						:to="{ name: 'organization-view' }"
						:is-expanded="props.isExpanded"
						data-testid="organization-link"
					>
						{{ $t("sidebar-menu-item.company") }}
					</sidebar-item>
				</nav>
			</div>
		</div>
		<div class="items-bottom is-hidden-mobile">
			<sidebar-expand-button
				data-testid="sidebar-expand-button"
				:aria-label="
					props.isExpanded
						? $t('sidebar-menu-item.collapse-sidepanel')
						: $t('sidebar-menu-item.expand-sidepanel')
				"
				v-tooltip="{
					content: props.isExpanded
						? $t('sidebar-menu-item.collapse-sidepanel')
						: $t('sidebar-menu-item.expand-sidepanel')
				}"
			/>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useAuth } from "@/composables/useAuth.js";
import { useBannersHeight } from "@/composables/useBannersHeight.js";
import SidebarItem from "./sidebar-item.vue";
import SidebarExpandButton from "./sidebar-expand-button.vue";
import {
	getBigLogoUrl,
	getSmallLogoUrl
} from "@/plugins/client-name/client-name.plugin.js";

const { matched } = useRoute();

const props = defineProps({
	isExpanded: {
		type: Boolean,
		default: true
	}
});

const { isReadOnlyUser } = useAuth();
const { bannerHeight, initTrackBannerHeight, stopTrackBannerHeight } =
	useBannersHeight();
const cssVars = computed(() => {
	return { bannerHeight: `${bannerHeight.value}px` };
});

const largeLogoUrl = ref(null);
const smallLogoUrl = ref(null);

const getLogoImg = computed(() => {
	return props.isExpanded ? largeLogoUrl.value : smallLogoUrl.value;
});

onMounted(async () => {
	largeLogoUrl.value = await getBigLogoUrl();
	smallLogoUrl.value = await getSmallLogoUrl();
	initTrackBannerHeight();
});

onUnmounted(() => {
	stopTrackBannerHeight();
});

/**
 * Check if current route matches any of the routeNames (when visiting a child route, the matched routes will return all routes from parent to current child route)
 *
 * @param {array|string} routeNames Route names as defined on routes file
 */
function isRouteActiveByRouteName(routeNames) {
	let routes = routeNames;
	if (typeof routeNames === "string") {
		routes = [routeNames];
	}

	const routeNamesMatchedFromCurrentRoute = matched?.flatMap(
		(routeMatched) => routeMatched?.name
	);
	const isActive = routeNamesMatchedFromCurrentRoute.some((routeMatched) =>
		routes.includes(routeMatched)
	);
	return isActive;
}
</script>

<style lang="scss" scoped>
.sidebar-items {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background: $color-grey-100;
	overflow-y: auto;
	padding-top: 0;
	padding-bottom: 1rem;

	.logo {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		padding: 0 1rem;

		& > img {
			width: auto;
			height: $navbar-height;
		}
		&:focus-visible {
			outline: 2px auto $focus-outline-color;
			outline-offset: -2px;
		}
	}

	.items {
		flex: 1 1 0%;
		flex-direction: column;
		overflow-y: auto;
		margin-top: 1rem;

		& > div {
			overflow-y: auto;

			& > nav {
				padding: 0 0.75rem 0.25rem 0.75rem;

				a {
					margin-top: 0.25rem;
				}
			}
		}

		hr {
			background-color: $color-grey-400;
			margin: 0.5rem;
			height: 1px;
		}

		&-bottom {
			display: flex;
			justify-content: center;
			padding-bottom: calc(1.5rem + v-bind("cssVars.bannerHeight"));
		}
	}
}
</style>
